import { PortalDialogProps } from "@app/services/modal.service";
import Modal from "../modal/modal.component";
import { Form, Formik, FormikContextType } from "formik";
import { FormControl } from "../form-control";
import Button from "../button";
import Input from "../input";
import { createRef } from "react";
import useObservable from "@core/hooks/use-observable.hook";
import { editPcValidationSchema } from "@app/validations";
import { UpdatePc } from "@app/types";
import PcService from "@app/services/http/pc.service";

function EditPcModal({ portalDialogRef, portalData }: PortalDialogProps) {
  const formRef = createRef<FormikContextType<UpdatePc>>();

  const { subscribeUntilDestroy } = useObservable();

  const initialValues = {
    macaddress: portalData.pc.macaddress,
    group: portalData.pc.group,
  };

  const handleSubmit = (values: UpdatePc) => {
    const pcUpdate: UpdatePc = {
      macaddress: values.macaddress,
      group: values.group,
    };

    subscribeUntilDestroy(
      PcService.editPc(portalData.domainSelected, pcUpdate),
      (pc) => {
        portalDialogRef.close({ pc: pc || true });
      }
    );
  };

  return (
    <Modal onCancel={portalDialogRef.close} buttonCancelInChildren>
      <div className="w-[300px] sm:w-[500px] p-5">
        <div className="font-bold text-xl">VPS</div>
        <Formik
          initialValues={initialValues}
          validationSchema={editPcValidationSchema}
          innerRef={formRef}
          onSubmit={handleSubmit}
          validateOnChange
          validateOnBlur
        >
          <Form>
            <div className="flex justify-between gap-5">
              <div className="flex-1">
                <div className="flex gap-2 items-center justify-between">
                  <div className="mt-3 flex-1">
                    <div className="text-sm">macaddress</div>
                    <FormControl name="macaddress">
                      <Input
                        className="mt-1 !max-w-none text-sm"
                        errorClassName="text-sm"
                        readOnly={true}
                      />
                    </FormControl>
                  </div>
                  <div className="mt-3 flex-1">
                    <div className="text-sm">group</div>
                    <FormControl name="group">
                      <Input
                        className="mt-1 !max-w-none text-sm"
                        errorClassName="text-sm"
                      />
                    </FormControl>
                  </div>
                </div>
              </div>
            </div>
            <Button
              type="submit"
              label={"Cập nhật"}
              containerClassName="mt-5 mx-auto"
              width="fit-content"
              className="px-6 text-sm"
              labelClassName="font-bold"
            />
          </Form>
        </Formik>
      </div>
    </Modal>
  );
}

export default EditPcModal;
