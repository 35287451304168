import { NormalSelectOptions } from "@app/components/select/select.type";

export enum VPS_OS {
  WINDOW_SERVER = "window server",
  UBUNTU = "ubuntu",
}

export enum VPS_Status {
  ALREADY = "already",
  ON_RENTAL = "on_rental",
  END = "end",
}

export const VPS_StatusLabel = {
  [VPS_Status.ALREADY]: "Sẵn sàng",
  [VPS_Status.ON_RENTAL]: "Đang thuê",
  [VPS_Status.END]: "Kết thúc",
};

export const FilterStatusOrderVpsOptions: NormalSelectOptions = [
  {
    label: "Tất cả",
    value: "",
  },
  {
    label: VPS_StatusLabel[VPS_Status.ON_RENTAL],
    value: VPS_Status.ON_RENTAL,
  },
  {
    label: VPS_StatusLabel[VPS_Status.END],
    value: VPS_Status.END,
  },
];

export const FilterStatusVpsOptions: NormalSelectOptions = [
  {
    label: "Tất cả",
    value: "",
  },
  {
    label: VPS_StatusLabel[VPS_Status.ALREADY],
    value: VPS_Status.ALREADY,
  },
  {
    label: VPS_StatusLabel[VPS_Status.ON_RENTAL],
    value: VPS_Status.ON_RENTAL,
  },
  {
    label: VPS_StatusLabel[VPS_Status.END],
    value: VPS_Status.END,
  },
];
