import { PcResponse, UpdatePc } from "@app/types";
import HttpService from "@core/services/http/http.service";

class _PcService {
  public getPCs(domain: string) {
    return HttpService.get<PcResponse>("/tool/devices/shows", {
      queryParams: {
        domain,
      },
    });
  }

  public editPc(domain: string, body: UpdatePc) {
    return HttpService.patch<any>("/tool/devices/update", {
      queryParams: {
        domain,
      },
      body: { ...body },
    });
  }

  public wol(macAddress: string, domain: string) {
    return HttpService.get<{ message: string }>(`/tool/wol/${macAddress}`, {
      queryParams: {
        domain,
      },
    });
  }

  public reset(ipAddress: string) {
    return HttpService.post<{ message: string }>("/tool/reset", {
      body: {
        id: ipAddress,
      },
    });
  }

  public shutDown(ipAddress: string, domain: string) {
    return HttpService.post<{ message: string }>(
      `/tool/shutdown/${ipAddress}`,
      {
        queryParams: {
          domain,
        },
      }
    );
  }

  public reConnect(body: {
    domain: string;
    username: string;
    ipaddress: string;
  }) {
    return HttpService.post<any>("/tool/reconnect", {
      body,
    });
  }
}

const PcService = new _PcService();

export default PcService;
