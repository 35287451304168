import Button from "@app/components/button";
import Input from "@app/components/input";
import Pagination from "@app/components/pagination";
import { Select } from "@app/components/select/select";
import {
  CURRENT_ACCOUNT,
  DEFAULT_DATE_TIME_FORMAT,
  DEFAULT_PAGE,
  DEFAULT_TOTAL_PAGES,
  FilterStatusOptions,
  OrderExtendName,
  OrderStatus,
  OrderStatusName,
  Role,
} from "@app/constants";
import OrderService from "@app/services/http/order.service";
import { Order } from "@app/types";
import { downloadFile, formatDate, formatMoneyVN } from "@app/utils/util";
import useObservable from "@core/hooks/use-observable.hook";
import StorageService from "@core/services/storage";
import dayjs from "dayjs";
import { ChangeEvent, useEffect, useState } from "react";
import { Subject, debounceTime } from "rxjs";

function OrderManagement() {
  const [page, setPage] = useState(DEFAULT_PAGE);
  const [totalPages, setTotalPages] = useState(DEFAULT_TOTAL_PAGES);
  const [orders, setOrders] = useState<Order[]>([]);
  const [search, setSearch] = useState("");
  const [username, setUsername] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [filterStatus, setFilterStatus] = useState<OrderStatus>();

  const currentAccount = StorageService.getObject(CURRENT_ACCOUNT) as any;

  const searchSubject = new Subject<string>();
  const usernameSubject = new Subject<string>();
  const fromDateSubject = new Subject<string>();
  const toDateSubject = new Subject<string>();

  const { subscribeOnce, subscribeUntilDestroy } = useObservable();

  useEffect(() => {
    searchSubject.pipe(debounceTime(300)).subscribe((data) => {
      setSearch(data);
    });
    usernameSubject.pipe(debounceTime(300)).subscribe((data) => {
      setUsername(data);
    });
    fromDateSubject.pipe(debounceTime(300)).subscribe((data) => {
      setFromDate(data);
    });
    toDateSubject.pipe(debounceTime(300)).subscribe((data) => {
      setToDate(data);
    });
  }, [searchSubject, usernameSubject, fromDateSubject, toDateSubject]);

  useEffect(() => {
    subscribeUntilDestroy(
      OrderService.getOrders(
        page,
        search,
        username,
        fromDate,
        toDate,
        filterStatus
      ),
      (res) => {
        if (res.data.length) {
          setOrders(res.data);
          setTotalPages(res.total_page);
        } else {
          setOrders([]);
          setTotalPages(DEFAULT_TOTAL_PAGES);
        }
      }
    );
  }, [page, search, username, fromDate, toDate, filterStatus]);

  const onPageChange = (page: number) => {
    setPage(page);
  };

  const onFilterChange = (e: ChangeEvent<HTMLInputElement>) => {
    searchSubject.next(e.target.value);
  };

  const onUsernameChange = (e: ChangeEvent<HTMLInputElement>) => {
    usernameSubject.next(e.target.value);
  };

  const onFromDateChange = (e: ChangeEvent<HTMLInputElement>) => {
    fromDateSubject.next(e.target.value);
  };

  const onToDateChange = (e: ChangeEvent<HTMLInputElement>) => {
    toDateSubject.next(e.target.value);
  };

  const handleFilterStatusChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setFilterStatus(e.target.value as any);
  };

  function onGetFileExcel() {
    subscribeOnce(
      OrderService.getExcelFileOrders(
        search,
        username,
        fromDate,
        toDate,
        filterStatus
      ),
      (data) => {
        const contentDisposition = data.responseHeaders["content-disposition"];

        const fileNameOriginal = contentDisposition?.split("filename=")?.[1];

        if (fileNameOriginal) {
          const fileName = fileNameOriginal.substring(
            1,
            fileNameOriginal.length - 2
          );

          if (fileName) {
            downloadFile(
              data.response,
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              fileName
            );
          }
        }
      }
    );
  }

  return (
    <div className="w-full mx-auto mt-4 pb-4">
      <div className="max-sm:flex-col flex gap-3 pb-2 px-2 border-b border-[#409EFF]">
        <Input
          className="mt-0 max-w-[250px]"
          placeholder="Nhập Image ID, IP, MAC, GROUP"
          onChange={onFilterChange}
        />
        <Input
          className="mt-0 max-w-[250px]"
          placeholder="Nhập Username"
          onChange={onUsernameChange}
        />
        <Input
          type="date"
          className="mt-0 max-w-[250px]"
          placeholder="Nhập Ngày"
          onChange={onFromDateChange}
        />
        <Input
          type="date"
          className="mt-0 max-w-[250px]"
          placeholder="Đến Ngày"
          onChange={onToDateChange}
        />
        <Select
          width={110}
          className="h-6 !mt-0"
          options={FilterStatusOptions}
          value={filterStatus}
          defaultValue={-1}
          onChange={handleFilterStatusChange}
        />

        <Button width={110} label="TảiExcel" onClick={onGetFileExcel} />
      </div>
      <div className="px-2 overflow-x-auto mt-2">
        <div className="inline-block min-w-full shadow-md rounded-lg overflow-hidden">
          <table className="min-w-full leading-normal">
            <thead className="text-white">
              <tr className="border-b-2 border-gray-200 bg-[#409EFF] text-sm">
                <th className="p-3 text-left w-40">User - ID</th>
                <th className="p-3 text-left w-40">IP - Image ID</th>
                <th className="p-3 text-left w-40">MAC</th>
                <th className="p-3 text-left w-40">Group</th>
                <th className="p-3 text-left w-40">Server</th>
                {currentAccount.role !== Role.STAFF && (
                  <th className="p-3 text-left w-40">Pass RDP</th>
                )}
                <th className="p-3 text-left w-40">Thời Gian Thuê</th>
                <th className="p-3 text-left w-40">Giá Tiền</th>
                <th className="p-3 text-left w-40">Start Time</th>
                <th className="p-3 text-left w-40">End Time</th>
                <th className="p-3 text-left w-40">Trạng Thái</th>
                <th className="p-3 text-left w-40">Gia Hạn</th>
                <th className="p-3 text-left w-40">Tiền Gia Hạn</th>
                <th className="p-3 text-left w-40">Tổng tiền</th>
              </tr>
            </thead>
            <tbody>
              {!!orders.length &&
                orders.map((order) => (
                  <tr
                    key={order._id}
                    className="border-b border-gray-200 text-sm hover:bg-[#51b83c2c]"
                  >
                    <td className="p-3">
                      {order.image?.account?.username + " - " + order._id}
                    </td>
                    <td className="p-3">{order.ip}</td>
                    <td className="p-3">{order.mac}</td>
                    <td className="p-3">{order.group}</td>
                    <td className="p-3">{order.server}</td>
                    {currentAccount.role !== Role.STAFF && (
                      <td className="p-3">{order.passRdp}</td>
                    )}
                    <td className="p-3">{order.rental_period}h</td>
                    <td className="p-3">
                      {order.image?.package?.price_per_hour &&
                        formatMoneyVN(order.image?.package?.price_per_hour) +
                          "đ/h\n" +
                          formatMoneyVN(order.image?.package?.price_per_day) +
                          "đ/ngày\n" +
                          formatMoneyVN(order.image?.package?.price_per_month) +
                          "đ/tháng"}
                    </td>
                    <td className="p-3">{formatDate(order.time_boot)}</td>
                    <td className="p-3">
                      {!order.status
                        ? formatDate(order.end_time)
                        : dayjs(order.time_boot)
                            .add(order.rental_period, "h")
                            .format(DEFAULT_DATE_TIME_FORMAT)}
                    </td>
                    <td className="p-3">{OrderStatusName[order.status]}</td>
                    <td className="p-3">{OrderExtendName[order.after_exp]}</td>
                    <td className="p-3">{formatMoneyVN(order.total_price)}đ</td>
                    <td className="p-3">{formatMoneyVN(order.total_price)}đ</td>
                  </tr>
                ))}
            </tbody>
          </table>
          {!orders.length && (
            <div className="flex justify-center">Không có dữ liệu</div>
          )}
        </div>
      </div>
      {totalPages > 0 && (
        <div className="flex justify-center mt-3">
          <Pagination
            currentPage={page}
            totalPages={totalPages}
            onPageChange={onPageChange}
          />
        </div>
      )}
    </div>
  );
}

export default OrderManagement;
