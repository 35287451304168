import Input from "@app/components/input";
import Pagination from "@app/components/pagination";
import { Select } from "@app/components/select/select";
import {
  DEFAULT_DATE_TIME_FORMAT,
  DEFAULT_PAGE,
  DEFAULT_TOTAL_PAGES,
} from "@app/constants";
import {
  FilterStatusOrderVpsOptions,
  VPS_OS,
  VPS_Status,
  VPS_StatusLabel,
} from "@app/constants/vps.const";
import OrderVpsService from "@app/services/http/order.vps.service";
import { ComputerConfig } from "@app/types";
import { OrderVps } from "@app/types/order.vps.type";
import { formatDate, formatMoneyVN } from "@app/utils/util";
import useObservable from "@core/hooks/use-observable.hook";
import dayjs from "dayjs";
import { ChangeEvent, useEffect, useState } from "react";
import { Subject, debounceTime } from "rxjs";

function OrderVpsManagement() {
  const [page, setPage] = useState(DEFAULT_PAGE);
  const [totalPages, setTotalPages] = useState(DEFAULT_TOTAL_PAGES);
  const [ordersVps, setOrdersVps] = useState<OrderVps[]>([]);
  const [search, setSearch] = useState("");
  const [ip, setIP] = useState("");
  const [username, setUsername] = useState("");
  const [userVps, setUserVps] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [filterStatus, setFilterStatus] = useState<VPS_Status>();

  const searchSubject = new Subject<string>();
  const ipSubject = new Subject<string>();
  const usernameSubject = new Subject<string>();
  const userVpsSubject = new Subject<string>();
  const fromDateSubject = new Subject<string>();
  const toDateSubject = new Subject<string>();

  const { subscribeUntilDestroy } = useObservable();

  useEffect(() => {
    searchSubject.pipe(debounceTime(300)).subscribe((data) => {
      setSearch(data);
    });
    ipSubject.pipe(debounceTime(300)).subscribe((data) => {
      setIP(data);
    });
    usernameSubject.pipe(debounceTime(300)).subscribe((data) => {
      setUsername(data);
    });
    userVpsSubject.pipe(debounceTime(300)).subscribe((data) => {
      setUserVps(data);
    });
    fromDateSubject.pipe(debounceTime(300)).subscribe((data) => {
      setFromDate(data);
    });
    toDateSubject.pipe(debounceTime(300)).subscribe((data) => {
      setToDate(data);
    });
  }, [
    searchSubject,
    usernameSubject,
    userVpsSubject,
    fromDateSubject,
    toDateSubject,
  ]);

  useEffect(() => {
    subscribeUntilDestroy(
      OrderVpsService.getOrdersVps(
        page,
        search,
        ip,
        username,
        userVps,
        fromDate,
        toDate,
        filterStatus
      ),
      (res) => {
        if (res.data.length) {
          setOrdersVps(res.data);
          setTotalPages(res.total_page);
        } else {
          setOrdersVps([]);
          setTotalPages(DEFAULT_TOTAL_PAGES);
        }
      }
    );
  }, [page, search, ip, username, userVps, fromDate, toDate, filterStatus]);

  const onPageChange = (page: number) => {
    setPage(page);
  };

  const onFilterChange = (e: ChangeEvent<HTMLInputElement>) => {
    searchSubject.next(e.target.value);
  };

  const onIPChange = (e: ChangeEvent<HTMLInputElement>) => {
    ipSubject.next(e.target.value);
  };

  const onUsernameChange = (e: ChangeEvent<HTMLInputElement>) => {
    usernameSubject.next(e.target.value);
  };

  const onUserVpsChange = (e: ChangeEvent<HTMLInputElement>) => {
    userVpsSubject.next(e.target.value);
  };

  const onFromDateChange = (e: ChangeEvent<HTMLInputElement>) => {
    fromDateSubject.next(e.target.value);
  };

  const onToDateChange = (e: ChangeEvent<HTMLInputElement>) => {
    toDateSubject.next(e.target.value);
  };

  const handleFilterStatusChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setFilterStatus(e.target.value as VPS_Status);
  };

  return (
    <div className="w-full mx-auto mt-4 pb-4">
      <div className="max-sm:flex-col flex gap-3 pb-2 px-2 border-b border-[#409EFF]">
        <Input
          className="mt-0 max-w-[250px]"
          placeholder="Nhập Mã Cấu Hình"
          onChange={onFilterChange}
        />
        <Input
          className="mt-0 max-w-[250px]"
          placeholder="Nhập IP Remote/SSH"
          onChange={onIPChange}
        />
        <Input
          className="mt-0 max-w-[250px]"
          placeholder="Nhập Username"
          onChange={onUsernameChange}
        />
        <Input
          className="mt-0 max-w-[250px]"
          placeholder="Nhập User VPS"
          onChange={onUserVpsChange}
        />
        <Input
          type="date"
          className="mt-0 max-w-[250px]"
          placeholder="Nhập Ngày"
          onChange={onFromDateChange}
        />
        <Input
          type="date"
          className="mt-0 max-w-[250px]"
          placeholder="Đến Ngày"
          onChange={onToDateChange}
        />
        <Select
          width={110}
          placeholder="trạng thái"
          className="h-6 !mt-0"
          options={FilterStatusOrderVpsOptions}
          value={filterStatus}
          defaultValue={-1}
          onChange={handleFilterStatusChange}
        />
      </div>
      <div className="px-2 overflow-x-auto mt-2">
        <div className="inline-block min-w-full shadow-md rounded-lg overflow-hidden">
          <table className="min-w-full leading-normal">
            <thead className="text-white">
              <tr className="border-b-2 border-gray-200 bg-[#409EFF] text-sm">
                <th className="p-3 text-left w-40">User - ID</th>
                <th className="p-3 text-left w-40">ID VPS</th>
                <th className="p-3 text-left w-40">Mã Cấu Hình</th>
                <th className="p-3 text-left w-40">IpRemote / ssh</th>
                <th className="p-3 text-left w-40">User/Pass</th>
                <th className="p-3 text-left w-40">Giá Tiền</th>
                <th className="p-3 text-left w-40">Thời Gian Thuê</th>
                <th className="p-3 text-left w-40">Start Time</th>
                <th className="p-3 text-left w-40">End Time</th>
                <th className="p-3 text-left w-40">Đã gia hạn</th>
                <th className="p-3 text-left w-40">Trạng Thái</th>
              </tr>
            </thead>
            <tbody>
              {!!ordersVps.length &&
                ordersVps.map((order) => (
                  <tr
                    key={order._id}
                    className="border-b border-gray-200 text-sm hover:bg-[#51b83c2c]"
                  >
                    <td className="p-3">
                      {order.account.username + " - " + order._id}
                    </td>
                    <td className="p-3">{order.vps._id}</td>
                    <td className="p-3">
                      {(order.vps.config as ComputerConfig).config_code}
                    </td>
                    <td className="p-3">
                      {order.vps.os === VPS_OS.UBUNTU
                        ? order.vps.ssh
                        : order.vps.ip_remote}
                    </td>
                    <td className="p-3">
                      {order.vps.user + "/" + order.vps.pass}
                    </td>
                    <td className="p-3">
                      {formatMoneyVN(
                        (order.vps.config as ComputerConfig)
                          .monthly_rental_price
                      )}{" "}
                      VNĐ
                    </td>
                    <td className="p-3">{order.rental_period} tháng</td>
                    <td className="p-3">{formatDate(order.time_start)}</td>
                    <td className="p-3">
                      {order.status === VPS_Status.END
                        ? formatDate(order.end_time)
                        : dayjs(order.time_start)
                            .add(order.rental_period, "month")
                            .format(DEFAULT_DATE_TIME_FORMAT)}
                    </td>
                    <td className="p-3">
                      {formatDate(order.has_been_extended ? "Có" : "Không")}
                    </td>
                    <td className="p-3">{VPS_StatusLabel[order.status]}</td>
                  </tr>
                ))}
            </tbody>
          </table>
          {!ordersVps.length && (
            <div className="flex justify-center">Không có dữ liệu</div>
          )}
        </div>
      </div>
      {totalPages > 0 && (
        <div className="flex justify-center mt-3">
          <Pagination
            currentPage={page}
            totalPages={totalPages}
            onPageChange={onPageChange}
          />
        </div>
      )}
    </div>
  );
}

export default OrderVpsManagement;
